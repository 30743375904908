// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"
import "./src/_variables.scss"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

import React from 'react';
import {AnimatePresence} from 'framer-motion';
import {AnimationContextProvider} from './src/contexts/animation-context';

export const wrapPageElement = ({element}) => (
  <AnimatePresence initial={false}>
    <AnimationContextProvider>
      {element}
    </AnimationContextProvider>
  </AnimatePresence>
)
