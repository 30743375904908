import React from "react";
import { useAnimation, Variants, AnimationControls } from "framer-motion";
import { animationVariants } from "../utilities/animation";

interface AnimationContextValues {
  variants: Variants;
  homePageAnimationControls: AnimationControls;
  blogsPageAnimationControls: AnimationControls;
  blogPostPageAnimationControls: AnimationControls;
}

const animationControlsStub: AnimationControls = {
  subscribe: () => () => {},
  start: () => Promise.resolve(),
  set: () => {},
  stop: () => {},
  mount: () => () => {},
};

const defaultValues: AnimationContextValues = {
  variants: {},
  homePageAnimationControls: animationControlsStub,
  blogsPageAnimationControls: animationControlsStub,
  blogPostPageAnimationControls: animationControlsStub,
};

const AnimationContext =
  React.createContext<AnimationContextValues>(defaultValues);

const useAnimationContext = (): AnimationContextValues =>
  React.useContext(AnimationContext);

export default useAnimationContext;

const createAnimationContext = (): AnimationContextValues => {
  const homePageAnimationControls = useAnimation();
  const blogsPageAnimationControls = useAnimation();
  const blogPostPageAnimationControls = useAnimation();

  return {
    variants: animationVariants,
    homePageAnimationControls,
    blogsPageAnimationControls,
    blogPostPageAnimationControls,
  };
};

export const AnimationContextProvider = ({
  children,
}: React.PropsWithChildren<any>): JSX.Element => (
  <AnimationContext.Provider
    value={createAnimationContext()}
    // `<AnimatePresence>` in `gatsby-browser.js` requires a key for its direct children.
    key="animation-context-provider"
  >
    {children}
  </AnimationContext.Provider>
);
