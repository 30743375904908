import { VariantLabels } from "framer-motion";

export enum AnimationDirection {
  // hack: Has to start at 1, otherwise @reach/router will set
  // `initialAnimateDir` to undefined if 0.
  LTR = 1, // left to right
  RTL, // right to left
  UTB, // up to bottom
  BTU, // bottom to up
}

const transition = {
  type: "spring",
  mass: 0.25,
  stiffness: 100,
  duration: 0.2,
};
export const animationVariants = {
  visible: {
    x: 0,
    y: 0,
    opacity: 1,
    transition,
  },
  ltrStart: {
    x: "-100%",
    y: 0,
    opacity: 0,
    transition,
  },
  ltrEnd: {
    x: "100%",
    y: 0,
    opacity: 0,
    transition,
  },
  utbStart: {
    x: 0,
    // We use `vh` over `%` so that the animation speed stays consistent
    // regardless of page/content length (e.g. long blog post pages).
    y: "-100%",
    opacity: 0,
    transition,
  },
  utbEnd: {
    x: 0,
    y: "100%",
    opacity: 0,
    transition,
  },
};

export const getInitialVariant = (
  direction: AnimationDirection
): VariantLabels | undefined => {
  const { LTR, RTL, UTB, BTU } = AnimationDirection;

  switch (direction) {
    case LTR:
      return "ltrStart";
    case RTL:
      return "ltrEnd";
    case UTB:
      return "utbStart";
    case BTU:
      return "utbEnd";
    default:
      return undefined;
  }
};

export const getExitVariant = (
  direction: AnimationDirection
): VariantLabels | undefined => {
  const { LTR, RTL, UTB, BTU } = AnimationDirection;

  switch (direction) {
    case LTR:
      return "ltrEnd";
    case RTL:
      return "ltrStart";
    case UTB:
      return "utbEnd";
    case BTU:
      return "utbStart";
    default:
      return undefined;
  }
};
